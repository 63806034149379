import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import config from '../../data/SiteConfig';
import MarkdownSeo from '../components/SEO/MarkdownSEO';

export default function generalPagesTemplate({ data }) {
  const generalPageNode = data.markdownRemark;
  const generalPage = generalPageNode.frontmatter;

  return (
    <Layout>
      <Helmet>
        <title>{`${generalPage.title} | ${config.siteTitleShort}`}</title>
      </Helmet>
      <MarkdownSeo postNode={generalPageNode} url="" />
      <div>
        <div className="legacy">
          <div dangerouslySetInnerHTML={{ __html: generalPageNode.html }} />
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query generalPagesBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
